import { OverlayModule, type ConnectedPosition } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';

import { getOverlayVisibilityAfterOutsideClick } from '@clover/core/helpers/get-overlay-visibility-after-outside-click';
import { UserService } from '@clover/core/services/user.service';
import type { WorkflowType as LegacyWorkflowType } from '@clover/workflows/models/workflow';
import { WorkflowsService as LegacyWorkflowsService } from '@clover/workflows/workflows.service';
import { WorkflowType } from '@clover/workflows-v2/state/workflows-state.model';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownTextComponent } from '@design/overlays/dropdown/dropdown-text/dropdown-text.component';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';

@Component({
  selector: 'cc-workflows-header',
  standalone: true,
  imports: [ButtonComponent, DropdownComponent, DropdownActionComponent, OverlayModule, DropdownTextComponent],
  templateUrl: './workflows-header.component.html',
  styleUrl: './workflows-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowsHeaderComponent {
  protected dropdownVisible = false;
  protected readonly dropdownPositionStrategy: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
      offsetY: 4,
    },
  ];

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly WorkflowType = WorkflowType;
  protected readonly getOverlayVisibilityAfterOutsideClick = getOverlayVisibilityAfterOutsideClick;

  private readonly legacyWorkflowsService = inject(LegacyWorkflowsService);
  private readonly router = inject(Router);
  private readonly userService = inject(UserService);

  get canCreateWorkflow(): boolean {
    return this.userService.permissions['Workflow_Create'];
  }

  async createNewWorkflow(type: WorkflowType): Promise<void> {
    this.legacyWorkflowsService.createdWorkflowType = type as LegacyWorkflowType;
    await this.router.navigate(['', 'workflows', 'create']);
  }
}
